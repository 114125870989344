/**第二版数仓路由*/
import Layout from '@/layout/index'

export const DataResources = {
    path: '/SecondEdition',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "数据资源",
    meta: {
        title: '数据资源',
        // icon: 'el-icon-s-tools'
        icon: 'el-icon-monitor'
    },
    children: [
        {
            path: 'DataResources',
            component: () => import('@/views/SecondEdition/DataResources/index'),
            name: 'DataResources',
            meta: {title: '数据资源', icon: 'el-icon-monitor'}
        },
    ]
}
export const Programme = {
    path: '/Programme',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "方案管理",
    // meta: {
    //     title: '方案管理',
    //     // icon: 'el-icon-s-tools'
    //     icon: 'el-icon-monitor'
    // },
    children: [
        {
            path: 'Programme',
            component: () => import('@/views/SecondEdition/programme/index'),
            name: 'Programme',
            meta: {title: '方案管理', icon: 'el-icon-monitor'}
        }
    ]
}
export const DAModel = {
    path: '/DAModel',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "方案管理",
    // meta: {
    //     title: '方案管理',
    //     // icon: 'el-icon-s-tools'
    //     icon: 'el-icon-monitor'
    // },
    children: [
        {
            path: 'DAModel',
            component: () => import('@/views/SecondEdition/DAModel/index'),
            name: 'DAModel',
            meta: {title: '指标管理', icon: 'el-icon-monitor'}
        }
    ]
}
export const CustomDimension = {
    path: '/DAModel',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "方案管理",
    // meta: {
    //     title: '方案管理',
    //     // icon: 'el-icon-s-tools'
    //     icon: 'el-icon-monitor'
    // },
    children: [
        {
            path: 'CustomDimension',
            component: () => import('@/views/SecondEdition/CustomDimension/index'),
            name: 'CustomDimension',
            meta: {title: '维度管理', icon: 'el-icon-monitor'}
        }
    ]
}
export const TaskManagement = {
    path: '/DAModel',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "方案管理",
    // meta: {
    //     title: '方案管理',
    //     // icon: 'el-icon-s-tools'
    //     icon: 'el-icon-monitor'
    // },
    children: [
        {
            path: 'TaskManagement',
            component: () => import('@/views/SecondEdition/TaskManagement/index'),
            name: 'TaskManagement',
            meta: {title: '任务列表', icon: 'el-icon-monitor'}
        }
    ]
}
export const Specimen = {
    path: '/Specimen',
    component: Layout,
    // redirect: 'noRedirect',
    // alwaysShow: true, //一直显示根路由
    // name: "方案管理",
    // meta: {
    //     title: '方案管理',
    //     // icon: 'el-icon-s-tools'
    //     icon: 'el-icon-monitor'
    // },
    children: [
        {
            path: 'Specimen',
            component: () => import('@/views/SecondEdition/Specimen/index'),
            name: 'Specimen',
            meta: {title: '指标关联管理', icon: 'el-icon-monitor'}
        }
    ]
}
export const Dashboard = {
    path: '/Dashboard',
    component: Layout,
    redirect: '/Dashboard/list',
    name: "Dashboard",
    meta: {
        title: '仪表盘列表',
        icon: 'el-icon-monitor',
        // roles: ['admin', "editor"]
    },
    children: [

        {
            path: 'TestSecond',
            component: () => import('@/views/SecondEdition/Dashboard/list/index'),
            name: 'TestSecond',
            meta: {title: '主题列表', icon: 'el-icon-monitor'}
            // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
        },

    ]
}

export const settings = {
    path: '/potionSetting',
    component: Layout,
    redirect: '/potionSetting',
    name: "potionSetting",
    meta: {
        title: '报表中心',
        icon: 'el-icon-monitor',
        // roles: ['admin', "editor"]
    },
    children: [
        {
            path: 'Setting',
            component: () => import('@/views/SecondEdition/potionSetting/index.vue'),
            name: 'Setting',
            meta: {title: '报表中心', icon: 'el-icon-monitor'}
            // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
        },

    ]
}

export const system = {
    path: '/system',
    component: Layout,
    redirect: '/system',
    name: "system",
    meta: {
        title: '系统管理',
        icon: 'el-icon-monitor',
        // roles: ['admin', "editor"]
    },
    children: [
        {
            path: 'user',
            component: () => import('@/views/SecondEdition/userView/index.vue'),
            name: 'user',
            meta: {title: '用户管理', icon: 'el-icon-s-tools'}
        },
        {
            path: 'menu',
            component: () => import('@/views/SecondEdition/menuView/index.vue'),
            name: 'user',
            meta: {title: '菜单管理', icon: 'el-icon-s-tools'}
        },
        {
            path: 'openInterface',
            component: () => import('@/views/SecondEdition/OpenInterface/index.vue'),
            // name: 'OpenInterface',
            meta: {title: '开放接口', icon: 'el-icon-s-tools'},
            children: [
              {
                  path: 'businessCompany',
                  component: () => import('@/views/SecondEdition/BusinessCompany/index.vue'),
                  name: 'businessCompany',
                  meta: {title: '业务公司', icon: 'el-icon-s-tools'}
              },
              {
                  path: 'menuAdd',
                  component: () => import('@/views/SecondEdition/MenuAdd/index.vue'),
                  name: 'menuAdd',
                  meta: {title: '菜单添加', icon: 'el-icon-s-tools'}
              },
          ]
        },

    ]
}
export const IndicatorWarning = {
  path: '/IndicatorWarning',
  component: Layout,
  redirect: '/IndicatorWarning/list',
  name: "IndicatorWarning",
  meta: {
    title: '指标预警',
    icon: 'el-icon-monitor',
    // roles: ['admin', "editor"]
  },
  children: [

    {
      path: 'Warning',
      component: () => import('@/views/SecondEdition/IndicatorWarning/index'),
      name: 'Warning',
      meta: { title: '指标预警', icon: 'el-icon-monitor' }
      // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
    },

  ]
}
export const VisvalTem = {
  path: '/VisvalTem',
  component: Layout,
  // redirect: 'noRedirect',
  // alwaysShow: true, //一直显示根路由
  // name: "方案管理",
  // meta: {
  //     title: '方案管理',
  //     // icon: 'el-icon-s-tools'
  //     icon: 'el-icon-monitor'
  // },
  children: [
      {
          path: 'VisvalTem',
          component: () => import('@/views/SecondEdition/VisvalTem/index'),
          name: 'VisvalTem',
          meta: {title: '模板中心', icon: 'el-icon-monitor'}
      }
  ]
}
export const SecondRouter = [
  DataResources, Programme, DAModel, CustomDimension, TaskManagement, Specimen, Dashboard, settings, system , IndicatorWarning,VisvalTem
]