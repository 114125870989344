/*
 * @Author: zhoukai zk3366386@163.com
 * @Date: 2023-11-28 11:10:48
 * @LastEditors: zhoukai zk3366386@163.com
 * @LastEditTime: 2023-12-06 17:03:26
 * @FilePath: \ttWork\statistics\data-warehouse-front-end\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './assets/style/element-variables.scss'
// elemnt滚轮事件
import 'default-passive-events'
import '@/assets/style/index.scss' // global css
import '@/assets/style/ruoyi.scss' // ruoyi css
import Cookies from 'js-cookie'
import './permission' // permission control
Vue.config.productionTip = false
import './assets/icons' // icon
import plugins from './plugins' // plugins
import input from './utils/input';//输入框限制
import elSelectLoadmore from './utils/selectLoadmore';//输入框限制
// 右边拖拽框
import VueDragResize from 'vue-drag-resize'  
Vue.component('Dragger',VueDragResize)
// 全局注册组件（也可以使用局部注册）,图表组件
import "echarts";
import ECharts from 'vue-echarts'
Vue.component('v-chart', ECharts)
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts;
import axios from 'axios';

Vue.prototype.$http = axios;

// 颜色选择器

//**私有方法 全局挂载**/
import {IsArray, resetForm, SetObj, ArrayRules, IsString, DateRangeOperation} from "@/utils/validate"

import plTable from 'pl-table'
import 'pl-table/themes/index.css'
import 'pl-table/themes/plTableStyle.css'
import { Loading } from "element-ui";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

      
const servicesLoading = (node,str,lock,bg) => {
  return Loading.service({
 	//loading需要覆盖的DOM节点，默认为body
    target: document.querySelector(node),
    text: str,			 			 //加载文案
    lock,					 		 //同v-loading的修饰符
    background: bg					 //背景色与透明度
    // spinner: 'el-icon-loading',	 //加载图标
  })
}

// 全局方法挂载
Vue.prototype.IsArray = IsArray;// 判断是否为数组 并且数据是否有数据
Vue.prototype.resetForm = resetForm;//表单重置
Vue.prototype.SetObj = SetObj;//数组转对象
Vue.prototype.ArrayRules = ArrayRules;//判断数组中某字符是否符合要求
Vue.prototype.IsString = IsString;//判断字符串是否为空 为null 为undefined
Vue.prototype.DateRangeOperation = DateRangeOperation;//表单时间段处理
Vue.prototype.$servicesLoading = servicesLoading
Vue.use(plTable);
Vue.use(dataV)
Vue.use(input);
Vue.use(elSelectLoadmore);
Vue.use(plugins)
Vue.use(VXETable)
Vue.use(ElementUI, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    // locale: enLang // 如果使用中文，无需设置，请删除
})
new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
